
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
  export default class ProgressCard extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) hiddenPercent!: boolean
  @Prop({ type: String }) title!: string
  @Prop({ type: String }) icon!: string
  @Prop({ type: Number, required: true, default: 100 }) total!: number
  @Prop({ type: Number, required: true, default: 0 }) amount!: number
  @Prop({ type: String, default: 'grey' }) color!: string
  @Prop({ type: String, default: 'primary' }) progressColor!: string

  get progress () {
    return (this.amount / this.total * 100) || 0
  }
  }
